import React from "react";
import { Box, Typography, Button, Grid, CardContent, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "../../components/Header";
import { AboutUs1, AboutUs2 } from "../../components/Section/AboutUs";
import { achievements, StyledCard, StyledAvatar } from "../../components/Section/AchievementsUtils";
import Footer from "../../components/Footer";
import { handleContact } from "../../components/Header";

const TitleSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "90vh",
  background: "url('/images/tentai_haikei1.png') center center no-repeat",
  backgroundSize: "cover",
  position: "relative",
}));

const AchievementsSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: "url('/images/tentai_haikei3.png') bottom no-repeat",
  minHeight: "100vh",
  width: "100%",
  backgroundSize: "cover",
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const Home: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = 64;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box style={{ margin: "-8px" }}>
      <Header scrollToSection={scrollToSection} />
      <TitleSection id="home">
        <ResponsiveTypography variant="h2" fontWeight={700} gutterBottom color="white">
          Your Dream Guide
        </ResponsiveTypography>
        <Typography variant={isMobile ? "h6" : "h4"} fontWeight={500} gutterBottom color="white">
          ポケットに小さな道標を.
        </Typography>
      </TitleSection>
      <Grid
        container
        spacing={0}
        gap={4}
        id="about"
        sx={{ maxWidth: `min(1200px, calc(100% - 32px))`, margin: "20px auto" }}
        justifyContent="center"
      >
        <Grid item xs={10}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            会社概要
          </Typography>
          <Typography variant="body1" gutterBottom>
            Micompassは、最先端の技術を活用して、お客様のビジネスの成長をサポートする会社です。
            <br />
            私たちは、革新的なソリューションと高品質なサービスを提供することで、デジタル世界での活躍をサポートします。
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <AboutUs1 />
        </Grid>
        <Grid item xs={12} md={5}>
          <AboutUs2 />
        </Grid>
      </Grid>

      <AchievementsSection>
        <Grid
          container
          spacing={0}
          gap={4}
          id="achievements"
          sx={{ maxWidth: `min(1200px, calc(100% - 32px))`, margin: "20px auto" }}
          justifyContent="center"
        >
          <Grid item xs={10} sx={{ marginTop: 4 }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom color="white">
              実績
            </Typography>
          </Grid>
          {achievements.map((achievement, index) => (
            <Grid item xs={12} md={5} key={index}>
              <StyledCard>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <StyledAvatar>{achievement.icon}</StyledAvatar>
                  <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
                    {achievement.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" textAlign="left">
                    {achievement.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box
          mt={4}
          style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#d83664",
              color: "#fff",
              borderRadius: 24,
              padding: "10px 20px",
              textTransform: "none",
              minWidth: 200,
              marginTop: 20,
              marginBottom: 20,
            }}
            onClick={handleContact}
          >
            お問い合わせ
          </Button>
        </Box>
      </AchievementsSection>

      <Footer />
    </Box>
  );
};

export default Home;
